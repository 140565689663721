import "./slide-show-block.scss";

import { arrayOf, node, object } from "prop-types";

import Container100 from "../Container/Container100.jsx";
import ImageBlockSlide from "./ImageBlockSlide.jsx";
import PlusSigns from "../PlusSigns/PlusSigns.jsx";
import SlideShow from "./SlideShow.jsx";
import useAOS from "../../common/useAOS.js";

const propTypes = {
  title: node,
  subtitle: node,
  slides: arrayOf(object).isRequired, // ImageBlock will type check
};

const SlideShowBlock = (props) => {
  const { title, subtitle, slides } = props;

  useAOS();

  return (
    <div
      data-aos="fade-down"
      data-aos-offset="100"
      className="slide-show-block"
    >
      <Container100>
        <PlusSigns isLeft={true} />
        <h2 className="slide-show-block__title">
          <strong>{title}</strong>
        </h2>
        {subtitle && (
          <div className="slide-show-block__subtitle">{subtitle}</div>
        )}
        <SlideShow>
          {slides.map((slide, i) => (
            <ImageBlockSlide key={i} {...slide} />
          ))}
        </SlideShow>
      </Container100>
    </div>
  );
};

SlideShowBlock.propTypes = propTypes;
export default SlideShowBlock;
