import * as R from "ramda";

import extractImageBlock from "./extractImageBlock.jsx";
import extractRichText from "./extractRichText.jsx";

export default R.compose(
  R.applySpec({
    title: R.prop("slideShowTitle"),
    subtitle: R.compose(
      R.unless(R.isNil, extractRichText),
      R.prop("slideShowSubtitle"),
    ),
    slides: R.compose(R.map(extractImageBlock), R.prop("slides")),
  }),
  R.defaultTo({}),
);
