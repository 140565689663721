import "./image-block-slide.scss";

import { node, object, shape, string } from "prop-types";

import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import MediaBlock from "../MediaBlock/MediaBlock.jsx";

const propTypes = {
  title: node.isRequired,
  text: node,
  image: node.isRequired,
  link: object, // for ContentfulLink
  tag: shape({
    title: string,
    slug: string,
    color: object,
  }),
};

const ImageBlockSlide = (props) => {
  const { title, text, image, link } = props;

  return (
    <section className="image-block-slide">
      <div className="image-block-slide__content">
        <h2 className="image-block-slide__title h1 outline-text">{title}</h2>
        {text && <div className="image-block-slide__text">{text}</div>}
        {link && (
          <ContentfulLink
            className="image-block-slide__link btn btn--big"
            {...link}
          />
        )}
      </div>
      <div className="image-block-slide__image">
        <MediaBlock>{image}</MediaBlock>
      </div>
    </section>
  );
};

ImageBlockSlide.propTypes = propTypes;
export default ImageBlockSlide;
