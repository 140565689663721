import "./slide-show.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { A11y, Navigation, Pagination } from "swiper/core";
import { useEffect, useState } from "react";

import { node } from "prop-types";

const propTypes = { children: node.isRequired };

SwiperCore.use([Navigation, Pagination, A11y]);

const SlideShow = (props) => {
  const { children } = props;

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <section className="slide-show">
      <Swiper
        a11y
        spaceBetween={50}
        slidesPerView={1}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          type: "custom",
          clickable: false,
          renderCustom: function (swiper, current, total) {
            const page = `${current}`.padStart(2, "0");
            const outOf = `${total}`.padStart(2, "0");
            return `<span class="slide-show__pagination">${page}<span class="slide-show__seperator">of</span> ${outOf}</span>`;
          },
        }}
      >
        {Array.isArray(children) ? (
          children.map((child, i) => <SwiperSlide key={i}>{child}</SwiperSlide>)
        ) : (
          <SwiperSlide>{children}</SwiperSlide>
        )}
      </Swiper>
      <button className="slide-show__prev swiper-button-prev" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.6 41.9">
          <path
            fill="currentColor"
            d="M68.6 21.138c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.2-1.2 1.1-1.2 3.3 0 4.4l11.2 11.9h-55c-1.7 0-3 1.3-3 3s1.3 3 3 3h55.1l-11.2 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.7-1.2.7-2z"
          />
        </svg>
      </button>
      <button className="slide-show__next swiper-button-next" type="button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.6 41.9">
          <path
            fill="currentColor"
            d="M68.6 21.138c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.2-1.2 1.1-1.2 3.3 0 4.4l11.2 11.9h-55c-1.7 0-3 1.3-3 3s1.3 3 3 3h55.1l-11.2 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.7-1.2.7-2z"
          />
        </svg>
      </button>
    </section>
  ) : null;
};

SlideShow.propTypes = propTypes;
export default SlideShow;
